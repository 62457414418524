<template>
  <div class="joinus">
    <div>
      <template v-if="language=='zh'">
        <myimage :url='src'></myimage>
      </template>
      <template v-if="language=='TW'">
        <myimage :url='srcTW'></myimage>
      </template>
      <template v-if="language=='En'">
        <myimage :url='srcEn'></myimage>
      </template>
      <div  :class="screenWidth >= 992 ? 'main-service hidden-xs-only' : 'main-service-sm hidden-xs-only'">
        <div class="ctype-con">
            <div v-for="(item,index) in ctypeList" :key='index' :class="ctypeindex==index?'activectype':''" @click="totypeclick(index)">{{item}}</div>
        </div>
        <template v-if="ctypeindex==0">
            <div v-html="content" style="text-align:left;padding-bottom:30PX;font-size:16PX"></div>
        </template>
        <template v-if="ctypeindex==1">
            <div v-html="content" style="text-align:left;padding-bottom:30PX;font-size:16PX"></div>
           
        </template>
      </div>
      <div class="hidden-sm-and-up join-phone">
         <div class="ctype-con">
            <div v-for="(item,index) in ctypeList" :key='index' :class="ctypeindex==index?'activectype':''"  @click="totypeclick(index)">{{item}}</div>
        </div>
        <template v-if="ctypeindex==0">
            <div v-html="content" style="text-align:left;padding-bottom:30PX;font-size:16PX"></div>
            
        </template>
        <template v-if="ctypeindex==1">
            <div v-html="content" style="text-align:left;padding-bottom:30PX;font-size:16PX"></div>
        </template>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import myimage from '@/components/myimage'
import "element-ui/lib/theme-chalk/display.css";
import {joinUs} from '@/api/index.js'
export default {
    components:{
        myimage
    },
    data(){
        return{
         src:require('../../assets/phone/joinus.png'),
          srcTW:require('../../assets/phone/joinusTW.png'),
          srcEn:require('../../assets/phone/joinusEn.png'),
         ctypeindex:0,
         screenWidth: document.body.clientWidth,//实时宽度
         screenHeight: document.body.clientHeight,//实时高度
         content:'',
          language:'zh'
        }
    },
  computed:{
    ctypeList(){
      return [
          this.$t('join.实习岗位'),
          this.$t('join.全职岗位'),
      ]
    }
  },
  mounted() {
    const that = this;
    that.language=localStorage.getItem('language')
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
    
    window.addEventListener("resize", () => {
       that.$nextTick(() => {
        that.$forceUpdate()
      });
    });
  },
  created(){
    this._joinUs(11)
  },
    methods:{
        //加入我们
        _joinUs(category){
            joinUs({
                category:category
            }).then(res=>{
                console.log(res)
                this.content=res.data.content
            })
        },
        totypeclick(e){
            this.ctypeindex=e
            if(e==0){
                this._joinUs(11)
            }
            if(e==1){
                this._joinUs(10)
            }
        }
    }

}
</script>

<style lang="scss">
.joinus{

.main-service {
  width:1176PX;
  margin: 0 auto;
}

.main-service-sm {
  width:1176PX;
  margin: 0 auto;
}
.ctype-con{
    width: 100%;
    border-bottom: 3PX solid rgba($color:  #FE992B, $alpha: 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 40PX 0 50PX 0;
    >div{
        height: 60PX;
        //line-height: 60PX;
        font-size: 20PX;
        text-align: center;
        width: 150PX!important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .activectype{
        background: #FE992B;
        color: #fff;
      width: 150PX!important;
        border-radius: 8PX 8PX 0 0;
    }
}
.join-title{
    font-size: 32PX;
    font-weight: 600;
    text-align: left;
}
.join-entitle{
     font-size: 24PX;
     color: #999;
    text-align: left;
    margin-bottom: 40PX;
}
.join-bottitle{
    font-size: 24PX;
    margin-bottom: 16PX;
    text-align: left;
}
.join-Detail{
    text-align: left;
    font-size: 18PX;
    line-height: 32PX;
    display: flex;
    margin-bottom: 40PX;
    .join-yellow{
        width: 10PX;
        height: 10PX;
        background: #FE992B;
        border-radius: 10PX;
        margin-right: 8PX;
        margin-top: 10PX;
    }
    >div:last-child{
        width: calc(100% - 20PX);
    }
}
.join-phone{
    width: 335PX;
    margin: 0 auto;
    .ctype-con{
    width: 100%;
    border-bottom: 3PX solid rgba($color:  #FE992B, $alpha: 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20PX 0 20PX 0;
    >div{
        height: 40PX;
        line-height: 40PX;
        font-size: 12PX;
        text-align: center;
        width: 65PX;
    }
    .activectype{
        background: #FE992B;
        color: #fff;
        border-radius: 8PX 8PX 0 0;
    }
}

.join-title{
    font-size: 18PX;
    font-weight: 600;
    text-align: left;
}
.join-entitle{
     font-size: 14PX;
     color: #999;
    text-align: left;
    margin-bottom: 20PX;
}
.join-bottitle{
    font-size: 16PX;
    margin-bottom: 16PX;
    text-align: left;
}
.join-Detail{
    text-align: left;
    font-size: 14PX;
    line-height: 24PX;
    display: flex;
    margin-bottom: 20PX;
    .join-yellow-sm{
        width: 10PX;
        height: 10PX;
        background: #FE992B;
        border-radius: 10PX;
        margin-right: 8PX;
        margin-top: 6PX;
    }
     >div:last-child{
        width: calc(100% - 20PX);
    }
}
}
}
</style>